<template>
    <div>
        <div class="modal_header">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple">
                        <div class="title_h">
                           {{ $t('message.update_currency') }}
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple-light">
                        <el-row class="button_r">
                            <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit">{{ $t('message.save_and_close') }}</el-button>
                            <el-button @click="resetForm('form')">{{ $t('message.close') }}</el-button>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="row">
            <div class="col-8 mt-2">
                <div class="modal-body">
                    <el-form ref="form" status-icon :model="form" :rules="rules" :label-position="'left'">
                        <el-col :span="22">
                            <el-form-item :label="columns.name.title" prop="name">
                                <el-input v-model="form.name" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item :label="columns.rate.title">
                                <el-input type="number" v-model="form.rate" autocomplete="off"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </div>
            </div>
            <!-- <div class="col-12">
                <div class="modal-footer">
                    <el-button @click="resetForm('form')" round size="mini">Отмень</el-button>
                    <el-button type="primary" @click="submit" round size="mini">Сохранить</el-button>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
  import {mapGetters,mapActions} from 'vuex';
  import form from '@/utils/mixins/form';
  export default {
    mixins: [form],
    props: {
      reloadModel: {type: Boolean, default: true}
    },
    data(){
      return {}
    },
    watch: {
      reloadModel: {
        handler: function () {
          if (this.reloadModel){
            this.loadModel()
          }
        },
        deep: true
      },
    },
    created(){},
    computed: {
      ...mapGetters({
        rules: 'currencies/rules',
        model: 'currencies/model',
        columns: 'currencies/columns'
      })
    },
    methods:{
      ...mapActions({
        save: 'currencies/update',
      }),
      loadModel(){
        this.form = JSON.parse( JSON.stringify( this.model ));
      },
      submit(close = true){
        this.$refs['form'].validate((valid) => {
          if (valid){
            this.loadingButton = true;
            this.save(this.form)
              .then(res => {
                this.loadingButton = false;
                this.$notify({
                  title: 'Успешно',
                  type: "success",
                  offset: 130,
                  message: res.message
                });
                this.resetForm('form');
                this.parent().listChanged();
              })
              .catch(err => {
                this.loadingButton = false;
                this.$notify({
                  title: 'Ошибка',
                  type: "error",
                  offset: 130,
                  message: err.error.message
                });
              });
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.$emit('c-close',{drawer: 'drawerUpdate'});
      },
    }
  }

</script>
